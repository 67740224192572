import { createFeature, createReducer, on } from '@ngrx/store';

import { getDefaultStoreEntityList } from '@ocp/utils-advanced/constants';
import { getEntityListHandlers } from '@ocp/utils-advanced/ngrx';
import type { OcpStoreEntityList } from '@ocp/utils-advanced/types';

import { DATA_MODEL_PROJECT_STORE_OPTIONS } from './data-model-project-store-options.constant';
import type { TDataModelProject } from '../../types';
import { dataModelProjectActions } from './data-model-project.actions';

type TStateExtend = {
  recordsAll: TDataModelProject[];
};
type TInitialState = OcpStoreEntityList<
  TDataModelProject,
  typeof DATA_MODEL_PROJECT_STORE_OPTIONS
> &
  TStateExtend;

const defaultState = getDefaultStoreEntityList<TDataModelProject>()(
  DATA_MODEL_PROJECT_STORE_OPTIONS,
);
const initialState: TInitialState = {
  ...defaultState,
  recordsAll: [],
};

const defaultHandlers = getEntityListHandlers<TDataModelProject, TStateExtend>()(
  DATA_MODEL_PROJECT_STORE_OPTIONS,
);

const dataModelProjectReducer = createReducer(
  initialState,
  on(dataModelProjectActions.loadList, (state) => defaultHandlers.loadList(state)),
  on(dataModelProjectActions.loadListSuccess, (state, { result: response }) => ({
    ...defaultHandlers.loadListSuccess(state, response),
    recordsAll: response,
  })),
  on(dataModelProjectActions.setQueryData, (state, { queryData }) => ({
    ...state,
    records: state.recordsAll.filter((el) =>
      queryData.some((query) => String(el[query.field]).includes(query.q)),
    ),
  })),
  on(dataModelProjectActions.setSelectedItem, (state, { item }) => ({
    ...state,
    records: item ? state.recordsAll.filter((el) => el.id === item.id) : state.recordsAll,
    selectedItem: item,
  })),
  on(dataModelProjectActions.resetState, () => initialState),
);

export const dataModelProjectFeature = createFeature({
  name: 'dataModelProject',
  reducer: dataModelProjectReducer,
});
