import { createFeature, createReducer, on } from '@ngrx/store';

import { getDefaultStoreEntityList } from '@ocp/utils-advanced/constants';
import { getEntityListHandlers } from '@ocp/utils-advanced/ngrx';
import type { OcpStoreEntityList } from '@ocp/utils-advanced/types';
import type { OcpQueryData } from '@ocp/utils/types';

import type {
  TDataModelClass,
  TDataModelClassQueryField,
  TDataModelClassSortField,
} from '../../types';
import { dataModelClassActions } from './data-model-class.actions';
import { DATA_MODEL_CLASS_STORE_OPTIONS } from './data-model-class-store-options.constant';

type TInitialState = OcpStoreEntityList<
  TDataModelClass,
  typeof DATA_MODEL_CLASS_STORE_OPTIONS,
  TDataModelClassQueryField,
  TDataModelClassSortField
>;

const defaultQueryData: OcpQueryData<TDataModelClassQueryField>[] = [
  {
    q: '',
    field: 'name',
    type: 'CONTAINS',
  },
];
const defaultState = getDefaultStoreEntityList<
  TDataModelClass,
  TDataModelClassQueryField,
  TDataModelClassSortField
>()(DATA_MODEL_CLASS_STORE_OPTIONS);
const initialState: TInitialState = {
  ...defaultState,
  query: {
    ...defaultState.query,
    data: defaultQueryData,
  },
  sort: { by: 'name', order: 'ASC' },
};

const defaultHandlers = getEntityListHandlers<
  TDataModelClass,
  object,
  TDataModelClassQueryField,
  TDataModelClassSortField
>()(DATA_MODEL_CLASS_STORE_OPTIONS);

const dataModelClassReducer = createReducer(
  initialState,
  on(dataModelClassActions.loadList, (state) => defaultHandlers.loadList(state)),
  on(dataModelClassActions.loadListSuccess, (state, { result: response }) =>
    defaultHandlers.loadListSuccess(state, response),
  ),
  on(dataModelClassActions.loadListFailure, (state, { error }) =>
    defaultHandlers.loadListFailure(state, error),
  ),
  on(dataModelClassActions.refreshListSuccess, (state, { result: response }) =>
    defaultHandlers.refreshListSuccess(state, response),
  ),
  on(dataModelClassActions.refreshListFailure, (state, { error }) =>
    defaultHandlers.refreshListFailure(state, error),
  ),
  on(dataModelClassActions.setPageSize, (state, { pageSize, refreshBehavior }) =>
    defaultHandlers.setPageSize(state, pageSize, refreshBehavior),
  ),
  on(dataModelClassActions.setPageIndex, (state, { pageIndex, refreshBehavior }) =>
    defaultHandlers.setPageIndex(state, pageIndex, refreshBehavior),
  ),
  on(dataModelClassActions.setSort, (state, { sort, refreshBehavior }) =>
    defaultHandlers.setSort(state, sort, refreshBehavior),
  ),
  on(dataModelClassActions.setQueryData, (state, { queryData }) =>
    defaultHandlers.setQueryData(state, queryData, defaultQueryData),
  ),
  on(dataModelClassActions.setSelectedItem, (state, { item }) =>
    defaultHandlers.setSelectedItem(state, item),
  ),
  on(dataModelClassActions.resetState, () => initialState),
);

export const dataModelClassFeature = createFeature({
  name: 'dataModelClass',
  reducer: dataModelClassReducer,
});
