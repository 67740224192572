import { createActionGroup } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { DATA_MODEL_PROJECT_STORE_OPTIONS } from './data-model-project-store-options.constant';
import type { TDataModelProject } from '../../types';

export const dataModelProjectActions = createActionGroup({
  source: 'DataModelProject',
  events: {
    ...getEntityListActions<TDataModelProject>()(DATA_MODEL_PROJECT_STORE_OPTIONS),
  },
});
