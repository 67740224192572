import { Injectable } from '@angular/core';

import { debounceTime, filter, type Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, pairwise, startWith } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { type ActionCreator, Store } from '@ngrx/store';

import { distinctUntilObjectChanged } from '@ocp/utils/rxjs';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions, globalFeature } from '@libs/core/store';

import type {
  TDataModelListRequest,
  TDataModelListResponse,
  TDataModelQueryField,
  TDataModelSortField,
} from '../../types';
import { dataModelProjectActions, dataModelProjectFeature } from '../data-model-project';
import { dataModelActions } from './data-model.actions';
import { DataModelWorkshopApiService } from '../../services';
import { dataModelFeature } from './data-model.reducer';

@Injectable({
  providedIn: 'root',
})
export class DataModelEffects {
  constructor(
    private _actions$: Actions,
    private _dataModelApiService: DataModelWorkshopApiService,
    private _store: Store,
  ) {}

  loadDataModel$ = createEffect(() =>
    this._fetchList$(
      [dataModelActions.setQueryData, dataModelProjectActions.setSelectedItem],
      dataModelActions.loadListSuccess,
      dataModelActions.loadListFailure,
    ),
  );

  refreshList$ = createEffect(() =>
    this._fetchList$(
      [dataModelActions.setPageSize, dataModelActions.setPageIndex, dataModelActions.setSort],
      dataModelActions.refreshListSuccess,
      dataModelActions.refreshListFailure,
    ),
  );

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelActions.loadList),
      withLatestFrom(
        this._store.select(dataModelProjectFeature.selectSelectedItem),
        this._store.select(dataModelFeature.selectPagination),
        this._store.select(dataModelFeature.selectSort),
        this._store.select(dataModelFeature.selectQuery),
        this._store.select(dataModelFeature.selectRecords),
      ),
      switchMap(([, dmwProject, pagination, sort, query, records]) => {
        if (dmwProject === null || Boolean(records.length)) {
          return of();
        }
        return this._dataModelApiService
          .getDataModelList$(
            { dmwProjectId: dmwProject.id },
            createListPayload<TDataModelSortField, TDataModelQueryField, TDataModelListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => dataModelActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(dataModelActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => dataModelActions.resetState()),
    ),
  );

  changedParentSelect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelProjectActions.setSelectedItem),
      startWith(null),
      pairwise(),
      filter(
        ([actionPrev, actionCurrent]) =>
          !actionCurrent?.item || actionCurrent.item !== actionPrev?.item,
      ),
      map(() => dataModelActions.resetState()),
    ),
  );

  // TODO: Replace 'any' with proper types
  private _fetchList$(
    actions: ActionCreator[],
    successCallback: (response: { result: TDataModelListResponse }) => void,
    errorCallback: (error: any) => any,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(dataModelProjectFeature.selectSelectedItem),
        this._store.select(dataModelFeature.selectPagination),
        this._store.select(dataModelFeature.selectSort),
        this._store.select(dataModelFeature.selectQuery),
      ),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([, dmwProject, pagination, sort, query]) => {
        if (dmwProject === null) {
          // TODO: Implement error handling
          return of();
        }
        return this._dataModelApiService
          .getDataModelList$(
            { dmwProjectId: dmwProject.id },
            createListPayload<TDataModelSortField, TDataModelQueryField, TDataModelListRequest>(
              pagination,
              sort,
              query.data,
            ),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
          );
      }),
    );
  }
}
