import { createActionGroup } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { DATA_MODEL_VERSION_STORE_OPTIONS } from './data-model-version-store-optiosn.constant';
import { type TDataModelVersion } from '../../types';

export const dataModelVersionActions = createActionGroup({
  source: 'DataModelVersion',
  events: {
    ...getEntityListActions<TDataModelVersion>()(DATA_MODEL_VERSION_STORE_OPTIONS),
  },
});
