import { Injectable } from '@angular/core';

import { debounceTime, filter, type Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, pairwise, startWith } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { type ActionCreator, Store } from '@ngrx/store';

import { distinctUntilObjectChanged } from '@ocp/utils/rxjs';

import { API_REQUEST_DEBOUNCE_TIME } from '@libs/core/constants';
import { createListPayload } from '@libs/core/utils';
import { globalActions, globalFeature } from '@libs/core/store';

import type {
  TDataModelClassListRequest,
  TDataModelClassListResponse,
  TDataModelClassQueryField,
  TDataModelClassSortField,
} from '../../types';
import { dataModelFeature } from '../data-model';
import { dataModelProjectFeature } from '../data-model-project';
import { dataModelVersionActions, dataModelVersionFeature } from '../data-model-version';
import { dataModelClassActions } from './data-model-class.actions';
import { DataModelWorkshopApiService } from '../../services';
import { dataModelClassFeature } from './data-model-class.reducer';

@Injectable({
  providedIn: 'root',
})
export class DataModelClassEffects {
  constructor(
    private _actions$: Actions,
    private _dataModelApiService: DataModelWorkshopApiService,
    private _store: Store,
  ) {}

  loadDataModelClass$ = createEffect(() =>
    this._fetchList$(
      [dataModelClassActions.setQueryData, dataModelVersionActions.setSelectedItem],
      dataModelClassActions.loadListSuccess,
      dataModelClassActions.loadListFailure,
    ),
  );

  refreshList$ = createEffect(() =>
    this._fetchList$(
      [
        dataModelClassActions.setPageSize,
        dataModelClassActions.setPageIndex,
        dataModelClassActions.setSort,
      ],
      dataModelClassActions.refreshListSuccess,
      dataModelClassActions.refreshListFailure,
    ),
  );

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelClassActions.loadList),
      withLatestFrom(
        this._store.select(dataModelProjectFeature.selectSelectedItem),
        this._store.select(dataModelFeature.selectSelectedItem),
        this._store.select(dataModelVersionFeature.selectSelectedItem),
        this._store.select(dataModelClassFeature.selectPagination),
        this._store.select(dataModelClassFeature.selectSort),
        this._store.select(dataModelClassFeature.selectQuery),
        this._store.select(dataModelClassFeature.selectRecords),
      ),
      switchMap(([, dmwProject, dataModel, dmwVersion, pagination, sort, query, records]) => {
        if (
          dmwProject == null ||
          dataModel == null ||
          dmwVersion == null ||
          Boolean(records.length)
        ) {
          // TODO: Implement error handling
          return of();
        }
        return this._dataModelApiService
          .getDataModelClassList$(
            {
              dmwProjectId: dmwProject.id,
              dataModelId: dataModel.id,
              dataModelVersionId: dmwVersion.id,
            },
            createListPayload<
              TDataModelClassSortField,
              TDataModelClassQueryField,
              TDataModelClassListRequest
            >(pagination, sort, query.data),
          )
          .pipe(
            map((response) => dataModelClassActions.loadListSuccess({ result: response })),
            catchError((error) => {
              return of(dataModelClassActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => dataModelClassActions.resetState()),
    ),
  );

  changedParentSelect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelVersionActions.setSelectedItem),
      startWith(null),
      pairwise(),
      filter(
        ([actionPrev, actionCurrent]) =>
          !actionCurrent?.item || actionCurrent.item !== actionPrev?.item,
      ),
      map(() => dataModelClassActions.resetState()),
    ),
  );

  // TODO: Replace 'any' with proper types
  private _fetchList$(
    actions: ActionCreator[],
    successCallback: (response: { result: TDataModelClassListResponse }) => void,
    errorCallback: (error: any) => any,
  ): Observable<any> {
    return this._actions$.pipe(
      ofType(...actions),
      debounceTime(API_REQUEST_DEBOUNCE_TIME),
      withLatestFrom(
        this._store.select(dataModelProjectFeature.selectSelectedItem),
        this._store.select(dataModelFeature.selectSelectedItem),
        this._store.select(dataModelVersionFeature.selectSelectedItem),
        this._store.select(dataModelClassFeature.selectPagination),
        this._store.select(dataModelClassFeature.selectSort),
        this._store.select(dataModelClassFeature.selectQuery),
      ),
      distinctUntilObjectChanged(this._store.select(globalFeature.selectOpenedProjectId)),
      switchMap(([, dmwProject, dataModel, dmwVersion, pagination, sort, query]) => {
        if (dmwProject == null || dataModel == null || dmwVersion == null) {
          // TODO: Implement error handling
          return of();
        }
        return this._dataModelApiService
          .getDataModelClassList$(
            {
              dmwProjectId: dmwProject.id,
              dataModelId: dataModel.id,
              dataModelVersionId: dmwVersion.id,
            },
            createListPayload<
              TDataModelClassSortField,
              TDataModelClassQueryField,
              TDataModelClassListRequest
            >(pagination, sort, query.data),
          )
          .pipe(
            map((response) => successCallback({ result: response })),
            catchError((error) => {
              return of(errorCallback({ error }));
            }),
          );
      }),
    );
  }
}
