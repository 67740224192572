import { Injectable } from '@angular/core';

import { filter, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { globalActions, globalFeature } from '@libs/core/store';

import { dataModelProjectFeature } from './data-model-project.reducer';
import { dataModelProjectActions } from './data-model-project.actions';
import { DataModelWorkshopApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class DataModelProjectEffects {
  constructor(
    private readonly _actions$: Actions,
    private readonly _store: Store,
    private readonly _dataModelApiService: DataModelWorkshopApiService,
  ) {}

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelProjectActions.loadList),
      withLatestFrom(
        this._store.select(globalFeature.selectOpenedProjectId),
        this._store.select(dataModelProjectFeature.selectRecords),
      ),
      switchMap(([, projectId, records]) => {
        if (projectId === null || Boolean(records.length)) {
          return of();
        }
        return this._dataModelApiService.getDataModelProjectList$().pipe(
          map((response) =>
            dataModelProjectActions.loadListSuccess({
              result: response,
            }),
          ),
          catchError((error) => {
            return of(dataModelProjectActions.loadListFailure({ error }));
          }),
        );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => dataModelProjectActions.resetState()),
    ),
  );
}
