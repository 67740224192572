import { createActionGroup } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { DATA_MODEL_CLASS_STORE_OPTIONS } from './data-model-class-store-options.constant';
import type {
  TDataModelClass,
  TDataModelClassQueryField,
  TDataModelClassSortField,
} from '../../types';

export const dataModelClassActions = createActionGroup({
  source: 'DataModelClass',
  events: {
    ...getEntityListActions<TDataModelClass, TDataModelClassQueryField, TDataModelClassSortField>()(
      DATA_MODEL_CLASS_STORE_OPTIONS,
    ),
  },
});
