import { createActionGroup } from '@ngrx/store';

import { getEntityListActions } from '@ocp/utils-advanced/ngrx';

import { DATA_MODEL_STORE_OPTIONS } from './data-model-store-options.constant';
import { type TDataModel, type TDataModelQueryField, type TDataModelSortField } from '../../types';

export const dataModelActions = createActionGroup({
  source: 'DataModel',
  events: {
    ...getEntityListActions<TDataModel, TDataModelQueryField, TDataModelSortField>()(
      DATA_MODEL_STORE_OPTIONS,
    ),
  },
});
