import { Injectable } from '@angular/core';

import { filter, of, switchMap, withLatestFrom } from 'rxjs';
import { catchError, map, pairwise, startWith } from 'rxjs/operators';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { globalActions } from '@libs/core/store';

import { dataModelActions, dataModelFeature } from '../data-model';
import { dataModelProjectFeature } from '../data-model-project';
import { dataModelVersionActions } from './data-model-version.actions';
import { DataModelWorkshopApiService } from '../../services';

@Injectable({
  providedIn: 'root',
})
export class DataModelVersionEffects {
  constructor(
    private _actions$: Actions,
    private _dataModelApiService: DataModelWorkshopApiService,
    private _store: Store,
  ) {}

  loadList$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelVersionActions.loadList, dataModelActions.setSelectedItem),
      withLatestFrom(
        this._store.select(dataModelProjectFeature.selectSelectedItem),
        this._store.select(dataModelFeature.selectSelectedItem),
      ),
      switchMap(([, dmwProject, dataModel]) => {
        if (!dmwProject || !dataModel) {
          return of();
        }
        return this._dataModelApiService
          .getDataModelVersionList$({
            dmwProjectId: dmwProject.id,
            dataModelId: dataModel.id,
          })
          .pipe(
            map((response) =>
              dataModelVersionActions.loadListSuccess({
                result: response,
              }),
            ),
            catchError((error) => {
              return of(dataModelVersionActions.loadListFailure({ error }));
            }),
          );
      }),
    ),
  );

  resetState$ = createEffect(() =>
    this._actions$.pipe(
      ofType(globalActions.openedProjectId),
      filter((action) => !action.projectId),
      map(() => dataModelVersionActions.resetState()),
    ),
  );

  changedParentSelect$ = createEffect(() =>
    this._actions$.pipe(
      ofType(dataModelActions.setSelectedItem),
      startWith(null),
      pairwise(),
      filter(
        ([actionPrev, actionCurrent]) =>
          !actionCurrent?.item || actionCurrent.item !== actionPrev?.item,
      ),
      map(() => dataModelVersionActions.resetState()),
    ),
  );
}
